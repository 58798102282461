import * as Yup from "yup";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { getSessionStorage, phoneAreaCodeMap } from "../../utils/config";

export const speiInitialValues = {
  speiName: "",
  speiBank: "",
  speiDocumentType: "",
  speiDocumentId: "",
  speiAccountType: "",
  speiAccount: "",
  speiEmail: "",
  speiCellphone: "",
};

export const speiValidationSchema = (country, t) =>
  Yup.lazy((values) => {
    return Yup.object({
      speiName: (() => {
        const r = t("home.formValidate.invalidName");
        return Yup.string().required(r);
      })(),
      speiBank: (() => {
        const r = t("home.formValidate.selectBank");
        return Yup.string().required(r);
      })(),
      speiDocumentType: (() => {
        const r = t("home.formValidate.selectDocumentType");
        return Yup.string().required(r);
      })(),
      speiDocumentId: (() => {
        const r = t("home.formValidate.invalidId");
        return Yup.string().required(r);
      })(),
      speiAccountType: (() => {
        const r = t("home.formValidate.selectAccountType");
        return Yup.string().required(r);
      })(),
      speiAccount: (() => {
        const r = t("home.formValidate.invalidAccount");
        return Yup.string().required(r);
      })(),
      speiEmail: (() => {
        const r1 = t("home.formValidate.invalidEmailAddress");
        const r2 = t("home.formValidate.inputEmail");
        return Yup.string().email(r1).required(r2);
      })(),
      speiCellphone: (() => {
        const r1 = t("home.formValidate.inputCellphone");
        const r2 = t("home.formValidate.invalidCellphone");
        return Yup.string()
          .required(r1)
          .test("check cellphone", r2, (val) => {
            const reg = phoneAreaCodeMap[country].reg;
            return reg.test(val) ? true : false;
          });
      })(),
    });
  });

export const walletInitialValues = {
  walletName: "",
  walletCpf_cnpj: "",
  walletAccount: "",
  walletEmail: "",
  walletCellphone: "",
};

export const walletValidationSchema = (country, t) =>
  Yup.lazy((values) => {
    return Yup.object({
      walletName: (() => {
        const r = t("home.formValidate.invalidName");
        return Yup.string().required(r);
      })(),
      walletCpf_cnpj: (() => {
        const r1 = t("home.formValidate.inputCpfOrCnpj");
        const r2 = t("home.formValidate.invalidCpfOrCnpj");
        return Yup.string()
          .required(r1)
          .test("check cpf|cnpj", r2, (val) => {
            if (cpf.isValid(val) || cnpj.isValid(val)) {
              return true;
            } else {
              return false;
            }
          });
      })(),
      walletAccount: (() => {
        const r1 = t("home.formValidate.invalidEmailAddress");
        const r2 = t("home.formValidate.invalidAccount");
        return Yup.string().email(r1).required(r2);
      })(),
      walletEmail: (() => {
        const r1 = t("home.formValidate.invalidEmailAddress");
        const r2 = t("home.formValidate.inputEmail");
        return Yup.string().email(r1).required(r2);
      })(),
      walletCellphone: (() => {
        const r1 = t("home.formValidate.inputCellphone");
        const r2 = t("home.formValidate.invalidCellphone");
        return Yup.string()
          .required(r1)
          .test("check cellphone", r2, (val) => {
            const reg = phoneAreaCodeMap[country].reg;
            return reg.test(val) ? true : false;
          });
      })(),
    });
  });

export const paypalInitialValues = {
  paypalCountry: "",
  paypalAccount: "",
  paypalConfirmAccount: "",
};

export const paypalValidationSchema = (country, t) =>
  Yup.lazy((values) => {
    return Yup.object({
      paypalCountry: (() => {
        const r = t("home.formValidate.selectCountry");
        return Yup.string().required(r);
      })(),
      paypalAccount: (() => {
        const r1 = t("home.formValidate.invalidEmailAddress");
        const r2 = t("home.formValidate.invalidAccount");
        return Yup.string().email(r1).required(r2);
      })(),
      paypalConfirmAccount: (() => {
        const r1 = t("home.formValidate.invalidEmailAddress");
        const r2 = t("home.formValidate.invalidAccount");
        const r3 = t("home.formValidate.inconsistent");
        return Yup.string()
          .email(r1)
          .required(r2)
          .test("check same account", r3, (val) => {
            return values.paypalAccount === val ? true : false;
          });
      })(),
    });
  });

export const pixInitialValues = {
  pixName: "",
  pixCpf_cnpj: "",
  pixKeyType: "",
  pixKey: "",
  pixEmail: "",
  pixCellphone: "",
};

export const pixValidationSchema = (country, t) =>
  Yup.lazy((values) => {
    return Yup.object({
      pixName: (() => {
        const r = t("home.formValidate.invalidName");
        return Yup.string().required(r);
      })(),
      pixCpf_cnpj: (() => {
        const r1 = t("home.formValidate.inputCpfOrCnpj");
        const r2 = t("home.formValidate.invalidCpfOrCnpj");
        return Yup.string()
          .required(r1)
          .test("check cpf|cnpj", r2, (val) => {
            if (cpf.isValid(val) || cnpj.isValid(val)) {
              return true;
            } else {
              return false;
            }
          });
      })(),
      pixKeyType: (() => {
        const r = t("home.formValidate.selectKeyType");
        return Yup.string().required(r);
      })(),
      pixKey: (() => {
        const r1 = t("home.formValidate.fillPixKey");
        const r2 = t("home.formValidate.invalidPixKey");
        return Yup.string()
          .required(r1)
          .test("check pix key", r2, (val) => {
            if (!values.pixKeyType) {
              return true;
            } else {
              if (values.pixKeyType === "CPF") {
                return cpf.isValid(val) ? true : false;
              } else if (values.pixKeyType === "CNPJ") {
                return cnpj.isValid(val) ? true : false;
              } else if (values.pixKeyType === "EMAIL") {
                const emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/;
                return emailReg.test(val) ? true : false;
              } else if (values.pixKeyType === "PHONE") {
                const phoneReg = /^(\+55|55)?\d{11}$/;
                return phoneReg.test(val) ? true : false;
              } else if (values.pixKeyType === "EVP") {
                const evpReg = /^([0-9a-zA-Z-]{32}|[0-9a-zA-Z-]{36})$/;
                return evpReg.test(val) ? true : false;
              } else {
                return true;
              }
            }
          });
      })(),
      pixEmail: (() => {
        const r1 = t("home.formValidate.invalidEmailAddress");
        const r2 = t("home.formValidate.inputEmail");
        return Yup.string().email(r1).required(r2);
      })(),
      pixCellphone: (() => {
        const r1 = t("home.formValidate.inputCellphone");
        const r2 = t("home.formValidate.invalidCellphone");
        return Yup.string()
          .required(r1)
          .test("check cellphone", r2, (val) => {
            const reg = phoneAreaCodeMap[country].reg;
            return reg.test(val) ? true : false;
          });
      })(),
    });
  });

export const banktranferInitialValues = {
  banktranferName: "",
  banktranferCpf_cnpj: "",
  banktranferBank: "",
  banktranferAccountType: "",
  banktranferAccountNumber: "",
  banktranferAccountDigit: "",
  banktranferBankBranch: "",
  banktranferBranchDigit: "",
  banktranferEmail: "",
  banktranferCellphone: "",
};
export const banktranferValidationSchema = (country, t) =>
  Yup.lazy((values) => {
    return Yup.object({
      banktranferName: (() => {
        const r = t("home.formValidate.invalidName");
        return Yup.string().required(r);
      })(),
      banktranferCpf_cnpj: (() => {
        const r1 = t("home.formValidate.inputCpfOrCnpj");
        const r2 = t("home.formValidate.invalidCpfOrCnpj");
        return Yup.string()
          .required(r1)
          .test("check cpf|cnpj", r2, (val) => {
            if (cpf.isValid(val) || cnpj.isValid(val)) {
              return true;
            } else {
              return false;
            }
          });
      })(),
      banktranferBank: (() => {
        const r = t("home.formValidate.selectBank");
        return Yup.string().required(r);
      })(),
      banktranferAccountType: (() => {
        const r = t("home.formValidate.selectAccountType");
        return Yup.string().required(r);
      })(),
      banktranferAccountNumber: (() => {
        const r = t("home.formValidate.inputAccountNumber");
        return Yup.string().required(r);
      })(),
      banktranferAccountDigit: (() => {
        const r1 = t("home.formValidate.accountDigitLen12");
        const r2 = t("home.formValidate.inputAccountDigit");
        return Yup.string().max(2, r1).required(r2);
      })(),
      banktranferBankBranch: (() => {
        const r1 = t("home.formValidate.accountDigitLen15");
        const r2 = t("home.formValidate.inputBankBranch");
        return Yup.string().max(5, r1).required(r2);
      })(),
      banktranferEmail: (() => {
        const r1 = t("home.formValidate.invalidEmailAddress");
        const r2 = t("home.formValidate.inputEmail");
        return Yup.string().email(r1).required(r2);
      })(),
      banktranferCellphone: (() => {
        const r1 = t("home.formValidate.inputCellphone");
        const r2 = t("home.formValidate.invalidCellphone");
        return Yup.string()
          .required(r1)
          .test("check cellphone", r2, (val) => {
            const reg = phoneAreaCodeMap[country].reg;
            return reg.test(val) ? true : false;
          });
      })(),
    });
  });

export const pixLabelMap = (t) => {
  return {
    name: t("home.formValidate.name"),
    cpf_cnpj: "CPF / CNPJ",
    key_type: t("home.formValidate.keyType"),
    pix_key: t("home.formValidate.pixType"),
    email: t("home.formValidate.email"),
    cellphone: t("home.formValidate.cellphone"),
  };
};

export const walletLabelMap = (t) => {
  return {
    name: t("home.formValidate.name"),
    cpf_cnpj: "CPF / CNPJ",
    account: t("home.formValidate.account"),
    email: t("home.formValidate.email"),
    cellphone: t("home.formValidate.cellphone"),
  };
};

export const banktranferLabelMap = (t) => {
  return {
    name: t("home.formValidate.name"),
    cpf_cnpj: "CPF / CNPJ",
    bank: t("home.formValidate.bank"),
    account_type: t("home.formValidate.accountType"),
    account_number: t("home.formValidate.accountNumber"),
    account_digit: t("home.formValidate.accountDigit"),
    bank_branch: t("home.formValidate.bankBranch"),
    branch_digit: t("home.formValidate.bankDigit"),
    email: t("home.formValidate.email"),
    cellphone: t("home.formValidate.cellphone"),
  };
};

export const speiLabelMap = (t) => {
  return {
    name: t("home.formValidate.name"),
    bank: t("home.formValidate.bank"),
    document_type: t("home.formValidate.documentType"),
    document_id: t("home.formValidate.documentId"),
    account_type: t("home.formValidate.accountType"),
    account: t("home.formValidate.account"),
    email: t("home.formValidate.email"),
    cellphone: t("home.formValidate.cellphone"),
  };
};

export const globalPaypalLabelMap = (t) => {
  return {
    country: t("home.formValidate.paypalCountry"),
    account: t("home.formValidate.paypalAccount"),
    confirmAccount: t("home.formValidate.rePaypalAccount"),
  };
};
