const dev_url = "https://sandbox.transfersmile.com";

const getUrl = () => {
  switch (window.location.hostname) {
    case "payout.pagsmile.com":
      return "https://api.transfersmile.com/";

    case "dev-payout.pagsmile.com":
      return "https://sandbox.transfersmile.com/";

    case "sandbox-payout.pagsmile.com":
      return "https://sandbox.transfersmile.com/";

    case "localhost":
      return "https://sandbox.transfersmile.com/";

    default:
      return "https://sandbox.transfersmile.com/";
  }
};

export const url = process.env.NODE_ENV === "development" ? dev_url : getUrl();
