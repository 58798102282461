import React from "react";
import fuzzySearch from "../../utils/fuzzySearch";
import Select from "react-select-search";
import "../../react-select-search.css";
import "./Form.scss";

function FormSelect({ ...props }) {
  const handleChange = (value) => {
    //console.log("value",value)
    props.onChange(props.name, value);
  };
  const handleBlur = () => {
    props.onBlur(props.name, true)
  }
  return (
    <div className="form-box">
      <label className="form-label">{props.label}</label>
      <Select
        options={props.options}
        value={props.value}
        autoComplete="off"
        onChange={(event) => handleChange(event)}
        onBlur={handleBlur}
        search
        filterOptions={fuzzySearch}
        emptyMessage="Not found"
      />

      {props.touched && !props.value && props.error ? (
        <div className="form-error">{props.error}</div>
      ) : null}
    </div>
  );
}

export default FormSelect;
