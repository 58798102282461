import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { privacyEn, privacyEs, privacyPt } from "./privacyPolicy";
import { homeEn, homeEs, homePt } from "./home";
import { setSessionStorage, getSessionStorage } from "../utils/config";

if (!getSessionStorage("lng")) {
  setSessionStorage("lng", "pt");
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: getSessionStorage("lng") || "pt",
    fallbackLng: getSessionStorage("lng") || "pt",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          ...privacyEn,
          ...homeEn,
          lng_text: "English",
          privacy_policy_error:
            "You must read and accept the Terms of Use and Privacy Policy to complete your purchase.",
          okay: "Okay!",
          help_chat: "Help chat",
          instructions: "Instructions",
          faq: "FAQ",
          videos: "Videos",
          pictures: "Pictures",
          text: "Text",
          home_step1: "Choose final country",
          home_step2: "Choose payment method",
          home_step3_t1: "Fill in the details",
          home_step3_t2: "RECEIVER's INFORMATION",
          time_remain: "Time remaining",
          payer: "PAYER",
          general: "GENERAL",
          transaction_id: "Transaction ID",
          description: "Description",
          currency: "CURRENCY",
          from: "From",
          exchange_rate: "Exchange rate",
          to: "To",
          taxes: "Taxes",
          bank_fee: "Bank fee",
          total: "TOTAL",
          confirm: "Confirm",
          back_txt: "Go back to merchant’s page",
          pricacy1: "I have read and agree to the terms of use and",
          pricacy2: "Privacy Policy",
          pricacy3: "of Pagsmile",
          transation_processing: "Processing transaction",
          transaction_success: "Transaction Successful",
          transaction_cancelled: "Transaction Cancelled",
          transaction_failed: "Transaction Failed",
          transaction_failed_label: "",
          result_tip1: "Thank you for paying with Pagsmile",
          result_tip2: "To know more about us, visit",
          try_again: "Try again",
          help: "Help",
          cancelled_tip1:
            "The time limit for your payment has ended, please return back to seller page to make a new order",
          live_chat: "Live chat",
          live_chat_tip1:
            "It is possible that the information you inputted was wrong.",
          live_chat_tip2:
            "Choose one of the buttons below to try again or talk us.",
          failed_label: "Your payment failed because",
        },
      },
      es: {
        translation: {
          ...privacyEs,
          ...homeEs,
          lng_text: "Español",
          privacy_policy_error:
            "Debe leer y aceptar los Términos de uso y la Política de privacidad para completar su compra.",
          okay: "Okay!",
          help_chat: "Chat de ayuda",
          instructions: "Instrucciones",
          faq: "Preguntas más frecuentes",
          videos: "Videos",
          pictures: "Fotos",
          text: "Texto",
          home_step1: "Selecciona el país final",
          home_step2: "Escoge el método de pago",
          home_step3_t1: "Complete los detalles",
          home_step3_t2: "INFORMACIÓN DEL RECEPTOR",
          time_remain: "Tiempo restante",
          payer: "PAGADOR",
          general: "GENERAL",
          transaction_id: "ID de transacción",
          description: "Descripción",
          currency: "MONEDA",
          from: "De",
          exchange_rate: "Tipo de cambio",
          to: "Para",
          taxes: "Impuestos",
          bank_fee: "Banco gratis",
          total: "TOTAL",
          confirm: "Confirmar",
          back_txt: "Regrese a la página del comerciante",
          pricacy1: "He leído y acepto los términos de uso y la",
          pricacy2: "Política de privacidad",
          pricacy3: "de Pagsmile",
          transation_processing: "Transacción en proceso",
          transaction_success: "Transacción exitosa",
          transaction_cancelled: "Transacción cancelada",
          transaction_failed: "Transacción fallida",
          transaction_failed_label: "",
          result_tip1: "Gracias por pagar con Pagsmile",
          result_tip2: "Para saber más sobre nosotros, click aquí",
          try_again: "Intentar de nuevo",
          help: "Ayuda",
          cancelled_tip1:
            "El tiempo límite de pago ha finalizado, vuelva a la página del vendedor para realizar un nuevo pedido.",
          live_chat: "Chat",
          live_chat_tip1:
            "É possível que as informações que você inseriu estejam erradas.",
          live_chat_tip2:
            "Escolha um dos botões abaixo para tentar novamente ou falar conosco.",
          failed_label: "Seu pagamento falhou porque",
        },
      },
      pt: {
        translation: {
          ...privacyPt,
          ...homePt,
          lng_text: "Português",
          privacy_policy_error:
            "Você deve ler e aceitar os Termos de Uso e a Política de Privacidade para concluir sua compra.",
          okay: "Certo!",
          help_chat: "Chat de ajuda",
          instructions: "Instruções",
          faq: "Perguntas frequentes",
          videos: "Videos",
          pictures: "Fotos",
          text: "Texto",
          home_step1: "Escolha o país final",
          home_step2: "Escolha o método de pagamento",
          home_step3_t1: "Preencha os detalhes",
          home_step3_t2: "INFORMAÇÕES DO RECEBEDOR",
          time_remain: "Tempo restante",
          payer: "PAGADOR",
          general: "EM GERAL",
          transaction_id: "ID de transação",
          description: "Descrição",
          currency: "MOEDA",
          from: "A partir de",
          exchange_rate: "Taxa de câmbio",
          to: "Para",
          taxes: "Impostos",
          bank_fee: "Banco grátis",
          total: "TOTAL",
          confirm: "Confirmar",
          back_txt: "Volte para a página do comerciante",
          pricacy1: "Eu li e concordo com os termos de uso e",
          pricacy2: "Política de Privacidade",
          pricacy3: "da Pagsmile",
          transation_processing: "Processando Transação",
          transaction_success: "Transação bem-sucedida",
          transaction_cancelled: "Transação cancelada",
          transaction_failed: "Falha na transação",
          transaction_failed_label: "",
          result_tip1: "Obrigado por pagar com Pagsmile",
          result_tip2: "Para saber mais sobre nós, visite",
          try_again: "Tente novamente",
          help: "Ajuda",
          cancelled_tip1:
            "O prazo para o seu pagamento terminou, volte à página do vendedor para fazer um novo pedido.",
          live_chat: "Chat",
          live_chat_tip1:
            "Es posible que la información que ingresaste sea incorrecta.",
          live_chat_tip2:
            "Elija uno de los botones siguientes para volver a intentarlo o póngase en contacto con nosotros.",
          failed_label: "Tu pago falló porque",
        },
      },
    },
  });

export default i18n;
