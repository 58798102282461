import { useField } from "formik";
import { _format, _formatCpf, _formatCnpj } from "../../utils/config";
import "./Form.scss";

function FormInput({ label, ...props }) {
  const [field, meta] = useField(props);
  const dealedValue = (value) => {
    if (props.name === "pixKey") {
      if (!props.id) {
        return value;
      } else if (props.id === "CPF") {
        return _formatCpf(value);
      } else if (props.id === "CNPJ") {
        return _formatCnpj(value);
      } else {
        return value;
      }
    } else if (props.name.indexOf("Cpf_cnpj") >= 0) {
      return _format(value);
    } else {
      return value;
    }
  };
  return (
    <div className="form-box">
      <label htmlFor={props.id || props.name} className="form-label">
        {label}
      </label>
      {!meta.value || meta.error ? (
        <input
          className="form-input"
          {...{ ...field, value: dealedValue(field.value) }}
          {...props}
          autoComplete="off"
        />
      ) : (
        <input
          className="form-input active"
          {...{ ...field, value: dealedValue(field.value) }}
          {...props}
          autoComplete="off"
        />
      )}
      {meta.touched && meta.error ? (
        <div className="form-error">{meta.error}</div>
      ) : null}
    </div>
  );
}

export default FormInput;
