import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { getQueryVariable, getSessionStorage, notify } from "./utils/config";
import { PuffLoader } from "react-spinners";

import $axios from "./utils/http";
import { setTradeInfo } from "./redux/actions";

import HomePage from "./page/homePage/HomePage";
import ResultPage from "./page/resultPage/ResultPage";
import _404Page from "./page/_404Page";

import Header from "./components/header/Header";
import "./App.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App(props) {
  const lng = getSessionStorage("lng") || "pt"
  const [isLoading, setIsloading] = useState(true);
  function setLiveChat(data) {
    //live-chat
    window.__lc = window.__lc || {};
    window.__lc.license = 8514118;
    window.__lc.chat_between_groups = false;
    window.__lc.group = 23;
    window.__lc.params = [
      { name: "app Name", value: data?.app_info?.app_name },
      { name: "Trade NO.", value: data?.out_trade_no },
      { name: "Order Amount", value: data?.order_amount },
    ];
    var lc = document.createElement("script");
    lc.type = "text/javascript";
    lc.async = true;
    lc.src =
      ("https:" == document.location.protocol ? "https://" : "http://") +
      "cdn.livechatinc.com/tracking.js";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(lc, s);
    //end live-chat
  }
  useEffect(() => {
    if (!getQueryVariable("t")) {
      notify("error", "Missing parameters!");
    } else {
      $axios({
        url: "/api/internal/checkout/detail",
        method: "POST",
      }).then((res) => {
        //console.log("res",res)
        if (!!res && res.code === 200) {
          setLiveChat(res.data);
          props.setTradeInfo(res.data);
          // 将所有数据处理完之后再将路由阀门打开
          setIsloading(false);
        }
      }).catch( err => {
        notify("error", err.msg);
        //console.log("err",err)
      })
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isLoading && (
        <div className="app__loader">
          <div className="loader">
            <PuffLoader color="#01b88f" />
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="App">
          <Header />
          <Router>
            {/* 独占路由 */}
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/result" component={ResultPage} />
              <Route component={_404Page} />
            </Switch>
          </Router>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTradeInfo: (item) => dispatch(setTradeInfo(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
