import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { setTradeFormDetail } from "../../redux/actions";
import { PuffLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

import SelectedImg from "../../images/selected_green.png";
import CloseImg from "../../images/close_gray.png";
import WarningImg from "../../images/warning.png";

import Select from "react-select-search";
import "../../react-select-search.css";

// component
import FormInput from "../../package/form/FormInput";
import FormSelect from "../../package/form/FormSelect";
import TradeForm from "../../components/tradeForm/TradeForm";

import PrivacyPolicy from "./PrivacyPolicy";

// utils
import $axios from "../../utils/http";
import * as config from "./formConfig";
import {
  methodConfig,
  getQueryVariable,
  notify,
  setSessionStorage,
  getSessionStorage,
  getPhoneAreaCode,
} from "../../utils/config";

// css
import "./HomePage.scss";

const classNames = require("classnames");

function HomePage(props) {
  //console.log("HomePage-props", props);
  const { t } = useTranslation();
  const token = getQueryVariable("t");
  const history = useHistory();
  const propsTradeInfo = props.tradeInfo;

  const countryOptions = propsTradeInfo.country_list;
  const speiDocumentTypeOptions = [
    { name: "RFC", value: "RFC" },
    { name: "CURP", value: "CURP" },
  ];

  const pixKeyTypeOptions = [
    { name: "CPF", value: "CPF" },
    { name: "CNPJ", value: "CNPJ" },
    { name: t("home.formSections.phone"), value: "PHONE" },
    { name: t("home.formSections.email"), value: "EMAIL" },
    { name: "EVP", value: "EVP" },
  ];

  const accountTypeOptions = [
    { name: t("home.formSections.checking"), value: "CHECKING" },
    { name: t("home.formSections.savings"), value: "SAVINGS" },
  ];

  const speiAccountTypeOptions = [
    { name: t("home.formSections.debit"), value: "DEBIT" },
    { name: t("home.formSections.phone"), value: "PHONE" },
    { name: "CLABE", value: "CLABE" },
  ];

  const [submitBtnFlag, setSubmitBtnFlag] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [bankOptions, setBankOptions] = useState([]);
  const [methodOptions, setMethodOptions] = useState([]);
  const [method, setMethod] = useState("");
  const [paypalCountryOptions, setPaypalCountryOptions] = useState([]);
  const [showPolicy, setShowPolicy] = useState(false);
  const [noticeText, setNoticeText] = useState("");
  const [selectNotice, setSelectNotice] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const handleChangeCountry = (val) => {
    if (val !== selectedCountry) {
      setMethod("");
      setStep2(false);
      props.setTradeFormDetail({});
    }
    setSelectedCountry(val);
    setStep1(true);
    propsTradeInfo.form_list.some((list) => {
      if (list.country === val) {
        const resMethod = list.method.map((item) => {
          if (item.name === "WALLET") {
            return {
              name: "PAYPAL",
              value: item.value,
            };
          } else {
            return {
              name: item.name,
              value: item.value,
            };
          }
        });
        setMethodOptions(resMethod);
        return true;
      }
    });
  };
  const handleChangeMethod = (value) => {
    let temp = `${selectedCountry}-${value}`;

    if (selectedCountry === "BRA") {
      if (value === "WALLET") {
        temp = `${selectedCountry}-${value}-USD`;
      } else {
        temp = `${selectedCountry}-${value}-BRL`;
      }
    } else if (selectedCountry === "MEX") {
      if (value === "WALLET") {
        temp = `${selectedCountry}-${value}-USD`;
      } else {
        temp = `${selectedCountry}-${value}-MXN`;
      }
    } else if (selectedCountry === "GLOBAL") {
      temp = `${selectedCountry}-${value}-USD`;
    }
    props.setTradeFormDetail(propsTradeInfo.fee_list[temp]);

    propsTradeInfo.form_list.some((list) => {
      if (list.country === selectedCountry) {
        list.method.some((item) => {
          if (item.name === value) {
            setBankOptions(item.bank_list);
            return true;
          }
        });
        return true;
      }
    });
    setMethod(value);
    setStep2(true);
  };
  // 获取global paypal的国家列表
  useEffect(() => {
    $axios({
      url: "/api/internal/checkout/paypal/countries",
      method: "POST",
    }).then((res) => {
      if (res.code === 200) {
        setPaypalCountryOptions(res.data);
      }
    });
  }, []);

  useEffect(() => {
    const curMethod = method;
    setMethod("");
    setTimeout(() => {
      setMethod(curMethod);
    }, 0);
  }, [props.language]);
  const closePolicy = () => {
    setShowPolicy(false);
  };
  const handleFormSubmitValidate = (props) => {
    props.submitForm();
  };
  const handleFormSubmit = (type, formValues) => {
    //console.log("formValues",formValues)

    let requestData = {
      country: selectedCountry,
      method: method,
    };
    let arrivalCurrency = "";
    if (selectedCountry === "BRA") {
      if (method === "WALLET") {
        arrivalCurrency = "USD";
      } else {
        arrivalCurrency = "BRL";
      }
    } else if (selectedCountry === "MEX") {
      if (method === "WALLET") {
        arrivalCurrency = "USD";
      } else {
        arrivalCurrency = "MXN";
      }
    } else if (selectedCountry === "GLOBAL") {
      arrivalCurrency = "USD";
    }
    if (type === "pix") {
      let documentType = "";
      if (formValues.pixCpf_cnpj.replace(/[^0-9]/gi, "").length !== 14) {
        documentType = "cpf";
      } else {
        documentType = "cnpj";
      }
      let account = "";
      if (formValues.pixKeyType === "PHONE") {
        account = getPhoneAreaCode(selectedCountry, formValues.pixKey);
      } else {
        account = formValues.pixKey;
      }
      requestData = {
        ...requestData,
        name: formValues.pixName,
        document_type: documentType,
        document_id: formValues.pixCpf_cnpj,
        account_type: formValues.pixKeyType,
        account: account,
        email: formValues.pixEmail,
        phone: formValues.pixCellphone,
        bank_code: "",
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (type === "banktranfer") {
      let documentType = "";
      if (
        formValues.banktranferCpf_cnpj.replace(/[^0-9]/gi, "").length !== 14
      ) {
        documentType = "cpf";
      } else {
        documentType = "cnpj";
      }
      requestData = {
        ...requestData,
        name: formValues.banktranferName,
        document_type: documentType,
        document_id: formValues.banktranferCpf_cnpj,
        account_type: formValues.banktranferAccountType,
        account: formValues.banktranferAccountNumber,
        email: formValues.banktranferEmail,
        phone: formValues.banktranferCellphone,
        bank_code: formValues.banktranferBank,
        branch: formValues.banktranferBankBranch,
        branch_digit: formValues.banktranferBranchDigit,
        account_digit: formValues.banktranferAccountDigit,
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (type === "wallet") {
      let documentType = "";
      if (formValues.walletCpf_cnpj.replace(/[^0-9]/gi, "").length !== 14) {
        documentType = "cpf";
      } else {
        documentType = "cnpj";
      }
      requestData = {
        ...requestData,
        name: formValues.walletName,
        document_type: documentType,
        document_id: formValues.walletCpf_cnpj,
        account_type: "EMAIL",
        account: formValues.walletAccount,
        email: formValues.walletEmail,
        phone: formValues.walletCellphone,
        bank_code: "",
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (type === "spei") {
      requestData = {
        ...requestData,
        name: formValues.speiName,
        document_type: formValues.speiDocumentType,
        document_id: formValues.speiDocumentId,
        account_type: formValues.speiAccountType,
        account: formValues.speiAccount,
        email: formValues.speiEmail,
        phone: formValues.speiCellphone,
        bank_code: formValues.speiBank,
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (type === "global-paypal") {
      requestData = {
        ...requestData,
        country: formValues.paypalCountry,
        account_type: "EMAIL",
        account: formValues.paypalAccount,
        email: "",
        phone: "",
        bank_code: "",
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "paypal",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    }

    // 协议是否勾选
    if (!props.privacyPolicy) {
      return false;
    }

    if (submitBtnFlag) {
      return false;
    }
    setIsloading(true);
    setSubmitBtnFlag(true);
    $axios({
      url: "/api/internal/checkout/commit",
      method: "POST",
      data: requestData,
      headers: {
        "Accept-Language": getSessionStorage("lng") || "pt",
      },
    })
      .then((res) => {
        if (res.code === 200) {
          notify("success", res.msg, 2000, () => {
            setSessionStorage("_t", res.data.token);
            setSessionStorage("_od", propsTradeInfo.order_detail);
            setSessionStorage("_td", props.tradeFormDetail);
            history.push("/result?t=" + token);
          });
        } else {
          setSubmitBtnFlag(false);
        }
      })
      .catch((err) => {
        setSubmitBtnFlag(false);
        setIsloading(false);
        notify("error", err.msg);
      });
  };

  return (
    <>
      {showPolicy && <PrivacyPolicy back={closePolicy} />}
      <div
        className="home__loader"
        style={{ display: isLoading ? "block" : "none" }}
      >
        <div className="loader">
          <PuffLoader color="#01b88f" />
        </div>
      </div>
      <div
        className="home__notice"
        style={{ display: selectNotice ? "block" : "none" }}
      >
        <div className="home__notice__warning">
          <img
            className="home__notice__warningClose"
            src={CloseImg}
            alt=""
            onClick={() => {
              setSelectNotice(false);
            }}
          />
          <img src={WarningImg} alt="" />
          <p className="home__notice__warningText">{noticeText}</p>
          <button
            className="home__notice__warningButton"
            onClick={() => {
              setSelectNotice(false);
            }}
          >
            {t("okay")}
          </button>
        </div>
      </div>
      <div className="home-wrap">
        <div className="home-box">
          <div className="box-left">
            <div className="step1">
              <span className="step-circle">1</span>
              <div
                className="step-next"
                style={{ borderLeftColor: step1 ? "#c1bfbf" : "transparent" }}
              >
                <h2 className="title">{t("home_step1")}</h2>
                <Select
                  options={countryOptions}
                  value={selectedCountry}
                  autoComplete="off"
                  onChange={handleChangeCountry}
                />
              </div>
            </div>
            <div
              className="step2"
              style={{ display: step1 ? "block" : "none" }}
            >
              <span className="step-circle">2</span>
              <div
                className="step-next"
                style={{ borderLeftColor: step2 ? "#c1bfbf" : "transparent" }}
              >
                <h2 className="title">{t("home_step2")}</h2>
                <div className="method-box">
                  {methodOptions.map((item) => (
                    <div
                      className="method"
                      key={item.value}
                      onClick={() => {
                        handleChangeMethod(item.value);
                      }}
                    >
                      <img
                        className={classNames("method-logo", {
                          "method-filter": item.value !== method,
                        })}
                        src={methodConfig()[item.value]}
                        alt=""
                      />
                      {item.value === method && (
                        <img
                          className="method-selected-img"
                          src={SelectedImg}
                          alt=""
                        />
                      )}
                      <p className="method-text">{item.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="step3"
              style={{ display: step2 ? "block" : "none" }}
            >
              <span className="step-circle">3</span>
              <div className="step-next" style={{ borderLeft: "none" }}>
                <h2 className="title">{t("home_step3_t1")}</h2>
                <h3 className="title-fu">{t("home_step3_t2")}</h3>
                {selectedCountry === "BRA" && method === "BANKTRANSFER" && (
                  <Formik
                    initialValues={config.banktranferInitialValues}
                    validationSchema={config.banktranferValidationSchema(
                      selectedCountry,
                      t
                    )}
                    onSubmit={async (values, { setSubmitting }) => {
                      handleFormSubmit("banktranfer", values);
                    }}
                  >
                    {(props) => (
                      <Form onSubmit={props.handleSubmit}>
                        <>
                          <div className="form-item">
                            <FormInput
                              label={config.banktranferLabelMap(t).name}
                              name="banktranferName"
                              type="text"
                            />
                            <FormInput
                              label={config.banktranferLabelMap(t).cpf_cnpj}
                              name="banktranferCpf_cnpj"
                              type="text"
                            />
                          </div>
                          <div className="form-item">
                            <FormSelect
                              label={config.banktranferLabelMap(t).bank}
                              name="banktranferBank"
                              options={bankOptions}
                              searchable={true}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              value={props.values.banktranferBank}
                              error={props.errors.banktranferBank}
                              touched={props.touched.banktranferBank}
                            />
                            <FormSelect
                              label={config.banktranferLabelMap(t).account_type}
                              name="banktranferAccountType"
                              options={accountTypeOptions}
                              searchable={true}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              value={props.values.banktranferAccountType}
                              error={props.errors.banktranferAccountType}
                              touched={props.touched.banktranferAccountType}
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={
                                config.banktranferLabelMap(t).account_number
                              }
                              name="banktranferAccountNumber"
                              type="text"
                            />
                            <FormInput
                              label={
                                config.banktranferLabelMap(t).account_digit
                              }
                              name="banktranferAccountDigit"
                              type="text"
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={config.banktranferLabelMap(t).bank_branch}
                              name="banktranferBankBranch"
                              type="text"
                            />
                            <FormInput
                              label={config.banktranferLabelMap(t).branch_digit}
                              name="banktranferBranchDigit"
                              type="text"
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={config.banktranferLabelMap(t).email}
                              name="banktranferEmail"
                              type="text"
                            />
                            <FormInput
                              label={config.banktranferLabelMap(t).cellphone}
                              name="banktranferCellphone"
                              type="text"
                            />
                          </div>
                          <button
                            type="button"
                            className="submit-form"
                            onClick={() => handleFormSubmitValidate(props)}
                          >
                            submit
                          </button>
                        </>
                      </Form>
                    )}
                  </Formik>
                )}
                {selectedCountry === "BRA" && method === "PIX" && (
                  <Formik
                    initialValues={config.pixInitialValues}
                    validationSchema={config.pixValidationSchema(
                      selectedCountry,
                      t
                    )}
                    onSubmit={async (values, { setSubmitting }) => {
                      handleFormSubmit("pix", values);
                    }}
                  >
                    {(props) => (
                      <Form onSubmit={props.handleSubmit}>
                        <>
                          <div className="form-item">
                            <FormInput
                              label={config.pixLabelMap(t).name}
                              name="pixName"
                              type="text"
                            />
                            <FormInput
                              label={config.pixLabelMap(t).cpf_cnpj}
                              name="pixCpf_cnpj"
                              type="text"
                            />
                          </div>
                          <div className="form-item">
                            <FormSelect
                              label={config.pixLabelMap(t).key_type}
                              name="pixKeyType"
                              options={pixKeyTypeOptions}
                              value={props.values.pixKeyType}
                              searchable={true}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              error={props.errors.pixKeyType}
                              touched={props.touched.pixKeyType}
                            />
                            <FormInput
                              label={config.pixLabelMap(t).pix_key}
                              name="pixKey"
                              id={props.values.pixKeyType}
                              type="text"
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={config.pixLabelMap(t).email}
                              name="pixEmail"
                              type="text"
                            />
                            <FormInput
                              label={config.pixLabelMap(t).cellphone}
                              name="pixCellphone"
                              type="text"
                            />
                          </div>
                          <button
                            type="button"
                            className="submit-form"
                            onClick={() => handleFormSubmitValidate(props)}
                          >
                            submit
                          </button>
                        </>
                      </Form>
                    )}
                  </Formik>
                )}
                {(selectedCountry === "BRA" || selectedCountry === "MEX") &&
                  method === "WALLET" && (
                    <Formik
                      initialValues={config.walletInitialValues}
                      validationSchema={config.walletValidationSchema(
                        selectedCountry,
                        t
                      )}
                      onSubmit={async (values, { setSubmitting }) => {
                        handleFormSubmit("wallet", values);
                      }}
                    >
                      {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                          <>
                            <div className="form-item">
                              <FormInput
                                label={config.walletLabelMap(t).name}
                                name="walletName"
                                type="text"
                              />
                              <FormInput
                                label={config.walletLabelMap(t).cpf_cnpj}
                                name="walletCpf_cnpj"
                                type="text"
                              />
                            </div>
                            <div className="form-item">
                              <FormInput
                                label={config.walletLabelMap(t).account}
                                name="walletAccount"
                                type="text"
                              />
                              <FormInput
                                label={config.walletLabelMap(t).email}
                                name="walletEmail"
                                type="text"
                              />
                            </div>
                            <div className="form-item">
                              <FormInput
                                label={config.walletLabelMap(t).cellphone}
                                name="walletCellphone"
                                type="text"
                              />
                            </div>
                            <button
                              type="button"
                              className="submit-form"
                              onClick={() => handleFormSubmitValidate(props)}
                            >
                              submit
                            </button>
                          </>
                        </Form>
                      )}
                    </Formik>
                  )}
                {selectedCountry === "MEX" && method === "SPEI" && (
                  <Formik
                    initialValues={config.speiInitialValues}
                    validationSchema={config.speiValidationSchema(
                      selectedCountry,
                      t
                    )}
                    onSubmit={async (values, { setSubmitting }) => {
                      //console.log("spei",values)
                      handleFormSubmit("spei", values);
                    }}
                  >
                    {(props) => (
                      <Form onSubmit={props.handleSubmit}>
                        <>
                          <div className="form-item">
                            <FormInput
                              label={config.speiLabelMap(t).name}
                              name="speiName"
                              type="text"
                            />
                            <FormSelect
                              label={config.speiLabelMap(t).bank}
                              name="speiBank"
                              options={bankOptions}
                              value={props.values.speiBank}
                              searchable={true}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              error={props.errors.speiBank}
                              touched={props.touched.speiBank}
                              aa={props}
                            />
                          </div>
                          <div className="form-item">
                            <FormSelect
                              label={config.speiLabelMap(t).document_type}
                              name="speiDocumentType"
                              options={speiDocumentTypeOptions}
                              value={props.values.speiDocumentType}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              error={props.errors.speiDocumentType}
                              touched={props.touched.speiDocumentType}
                            />
                            <FormInput
                              label={config.speiLabelMap(t).document_id}
                              name="speiDocumentId"
                              type="text"
                            />
                          </div>
                          <div className="form-item">
                            <FormSelect
                              label={config.speiLabelMap(t).account_type}
                              name="speiAccountType"
                              options={speiAccountTypeOptions}
                              value={props.values.speiAccountType}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              error={props.errors.speiAccountType}
                              touched={props.touched.speiAccountType}
                            />
                            <FormInput
                              label={config.speiLabelMap(t).account}
                              name="speiAccount"
                              type="text"
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={config.speiLabelMap(t).email}
                              name="speiEmail"
                              type="text"
                            />
                            <FormInput
                              label={config.speiLabelMap(t).cellphone}
                              name="speiCellphone"
                              type="text"
                            />
                          </div>
                          <button
                            type="button"
                            className="submit-form"
                            onClick={() => handleFormSubmitValidate(props)}
                          >
                            submit
                          </button>
                        </>
                      </Form>
                    )}
                  </Formik>
                )}
                {selectedCountry === "GLOBAL" && method === "WALLET" && (
                  <Formik
                    initialValues={config.paypalInitialValues}
                    validationSchema={config.paypalValidationSchema(
                      selectedCountry,
                      t
                    )}
                    onSubmit={async (values, { setSubmitting }) => {
                      //console.log("global-paypal", values)
                      handleFormSubmit("global-paypal", values);
                    }}
                  >
                    {(props) => (
                      <Form onSubmit={props.handleSubmit}>
                        <>
                          <div className="form-item">
                            <FormSelect
                              label={config.globalPaypalLabelMap(t).country}
                              name="paypalCountry"
                              options={paypalCountryOptions}
                              value={props.values.paypalCountry}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              error={props.errors.paypalCountry}
                              touched={props.touched.paypalCountry}
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={config.globalPaypalLabelMap(t).account}
                              name="paypalAccount"
                              type="text"
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={
                                config.globalPaypalLabelMap(t).confirmAccount
                              }
                              name="paypalConfirmAccount"
                              type="text"
                            />
                          </div>

                          <button
                            type="button"
                            className="submit-form"
                            onClick={() => handleFormSubmitValidate(props)}
                          >
                            submit
                          </button>
                        </>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
          <div className="box-right">
            <TradeForm
              handleSetShowPolicy={setShowPolicy}
              isHomePage={true}
              step1={step1}
              step2={step2}
              setSelectNotice={setSelectNotice}
              setNoticeText={setNoticeText}
            ></TradeForm>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    tradeFormDetail: state.tradeFormDetail,
    language: state.language,
    privacyPolicy: state.privacyPolicy,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setTradeFormDetail: (item) => dispatch(setTradeFormDetail(item)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
