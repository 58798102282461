import React, { useState, useEffect } from "react";

import ResultSuccess from "../../components/resultSuccess/ResultSuccess";
import ResultProcessing from "../../components/resultProcessing/ResultProcessing";
import ResultFail from "../../components/resultFail/ResultFail";
import ResultTimeEnd from "../../components/resultTimeEnd/ResultTimeEnd";
import TradeForm from "../../components/tradeForm/TradeForm";
import $axios from "../../utils/axios";
import "./ResultPage.scss";

function ResultPage() {
  
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  useEffect(() => {
    $axios({
      url: "/api/internal/checkout/status",
      method: "POST",
    })
      .then((res) => {
        if (res.code === 200) {
          setStatus(res.data.status);
          if (res.data.status === "REJECTED" || res.data.status === "CLOSED") {
            setDescription(res.data.description);
          }
        }
      })
      .catch((err) => {
        if (err.code === 4004001) {
          setStatus("TimeEND");
        }
      });
  }, []);

  return (
    <div className="result-wrap">
      <div className="result-box">
        <div className="result-left">
          {status === "TimeEND" && <ResultTimeEnd />}
          {status === "PAID" && <ResultSuccess />}
          {(status === "PROCESSING" || status === "IN_PROCESSING") && (
            <ResultProcessing />
          )}
          {(status === "REJECTED" || status === "CLOSED") && (
            <ResultFail errorDescription={description} />
          )}
        </div>
        <div>
          <TradeForm isHomePage={false}></TradeForm>
        </div>
      </div>
    </div>
  );
}
export default ResultPage;
